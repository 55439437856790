import './MobileNavigationOffcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { HeaderMenuItemContentType, MiscellaneousContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.svg';
import { SocialIcons } from './SocialIcons';

interface MobileNavigationOffcanvasProps extends b2x.OffcanvasProps {}

const MobileNavigationOffcanvas = (props: MobileNavigationOffcanvasProps) => {
  const menu = b2x.useMenu('MENU_HEADER_MOBILE', { populate: { content: true } });
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <b2x.Offcanvas {...props}>
      <div className="d-flex flex-column h-100 MobileNavigationOffcanvas">
        <Header />
        <div className="flex-grow-1 _overflow-auto position-relative">
          <b2x.Drawers itemsClassName="px-4 py-1 border-bottom">
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">
                <DrawerAccountItem2 />
                {menu && <DrawerMenuItems item={menu} textBold textUppercase />}
                <DrawerLocaleItem />
                <b2x.DrawerLinkItem to={getPagePath('SITE_STORE_LOCATOR')}>
                  <b2x.DrawerItem
                    className=""
                    height={50}
                    justifyContent="start"
                    start={{
                      center: (
                        <div className="hstack gap-2 small fw-bold">
                          <Icon name="store-locator" size={25} />
                          <span style={{ textTransform: 'initial' }}>Store locator</span>
                        </div>
                      ),
                    }}
                  />
                </b2x.DrawerLinkItem>
              </div>
              <Footer />
            </div>
          </b2x.Drawers>
        </div>
      </div>
    </b2x.Offcanvas>
  );
};

const Header = () => {
  const { close } = b2x.useModalContext();

  return (
    <div>
      <div className="px-4 py-3 border-bottom hstack gap-4 justify-content-between">
        <b2x.router.Link onClick={close} to="/">
          <b2x.Image className="logo" fluid src={logo} />
        </b2x.router.Link>
        <Button iconEnd={{ name: 'delete', size: 20 }} onClick={close} variant="blank" />
      </div>
    </div>
  );
};

const Footer = () => {
  const { info } = b2x.useAppContext();
  return (
    <div className="px-4 py-3 mb-3">
      <div className="hstack gap-2 mb-2">
        <SocialIcons />
      </div>
      <div className="small text-uppercase">
        {t('customerCare.contact')}
        <b2x.DeprecatedCta
          className="small text-uppercase text-decoration-none"
          cta={{ to: { href: `tel:${info?.customerCarePhoneNumber}` } }}
        >
          <span> {info?.customerCarePhoneNumber}</span>
        </b2x.DeprecatedCta>
      </div>
      <Icon name="newsletter" size={16} />
      <span className="small">
        <b2x.DeprecatedCta className="text-decoration-none" cta={{ to: { href: `mailto:${info?.customerCareEmail}` } }}>
          {info?.customerCareEmail}
        </b2x.DeprecatedCta>
      </span>
    </div>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = t('misc.back') }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
      <b2x.DrawerItem
        center={{ center: label }}
        className="extra-small fw-light gap-3"
        height={35}
        justifyContent="start"
        start={{ center: <Icon name="small-arrow-left" /> }}
      />
    </b2x.DrawerButtonItem>
  );
};

interface DrawerMenuItemsProps {
  // depth?: number;
  item: b2x.MenuApiDto;
  textBold?: boolean;
  textUppercase?: boolean;
}

const DrawerMenuItems = ({ item, textBold, textUppercase }: DrawerMenuItemsProps) => {
  const isLastBranch = React.useMemo(
    () => item.children.every((child) => child.children.length === 0),
    [item.children]
  );

  return (
    <>
      {item.children.map((child) => {
        const hasChildren = child.children.length > 0;
        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={child.id}
            newDrawer={
              <Drawer backItemProps={{ label: child.label }}>
                <DrawerMenuItems item={child} />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className={classnames(
                'small',
                { 'text-uppercase': textUppercase },
                { 'fw-bold': textBold },
                { 'fw-medium': !textBold }
              )}
              end={{ center: <Icon name="small-arrow-right" /> }}
              height={isLastBranch ? 40 : 50}
              justifyContent="between"
              start={{ center: child.label }}
            />
          </b2x.DrawerTriggerItem>
        ) : (
          <b2x.DrawerLinkItem key={child.id} to={child.link}>
            <b2x.DrawerItem
              className={classnames(
                'small',
                { 'text-uppercase': textUppercase },
                { 'fw-bold': textBold },
                { 'fw-medium': !textBold }
              )}
              height={isLastBranch ? 40 : 50}
              justifyContent="start"
              start={{ center: child.label }}
            />
          </b2x.DrawerLinkItem>
        );
      })}
      {item.link && (
        <b2x.DrawerLinkItem to={item.link}>
          <b2x.DrawerItem
            className="fw-semi-bold small"
            height={isLastBranch ? 40 : 50}
            justifyContent="start"
            start={{ center: t('misc.viewAll') }}
          />
        </b2x.DrawerLinkItem>
      )}
      {item.content &&
        b2x.typedContent<HeaderMenuItemContentType>(item.content, (content) => (
          <>
            <div className="p-4">
              <div className="mb-3">
                <b2x.ImageFromContent fluid {...content.body.img} />
              </div>
              <div className="fw-bold text-uppercase text-center">
                {content.body.cta && (
                  <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.cta}>
                    <span className="text-primary"> {content.body.cta.label}</span>
                  </b2x.DeprecatedCta>
                )}
              </div>
            </div>
          </>
        ))}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

const DrawerAccountItem2 = () => {
  const { close } = b2x.useModalContext();
  const { session } = b2x.useAppContext();
  const accountOffcanvasContent =
    b2x.useContent<b2x.contentTypes.AccountOffcanvasContentType>('ACCOUNT_OFFCANVAS_CONTENT');
  return session?.customer ? (
    <b2x.DrawerLinkItem to="/account">
      <b2x.DrawerItem
        className="fw-light text-uppercase small"
        height={50}
        justifyContent="start"
        start={{ center: 'Account' }}
      />
    </b2x.DrawerLinkItem>
  ) : (
    <b2x.DrawerTriggerItem
      newDrawer={
        <Drawer>
          <div className="d-flex flex-column h-100 justify-content-between">
            <div className="p-4">
              <h4 className="text-uppercase fw-semi-bold mb-3">Login</h4>
              <h6 className="fw-light mb-3">{t('account.insertCredentials')}</h6>
              <b2x.LoginForm onSuccess={close} />
            </div>
            <div>
              <div className="bg-lighter p-4">
                <h4 className="text-uppercase fw-semi-bold mb-3">
                  {b2x.formatHtml(accountOffcanvasContent?.body.registration?.title)}
                </h4>
                <h6 className="fw-light mb-4">{b2x.formatHtml(accountOffcanvasContent?.body.registration?.body)}</h6>
                <div className="d-grid">
                  <b2x.router.Link className="btn btn-primary" onClick={close} to="/register">
                    {t('account.register')}
                  </b2x.router.Link>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      }
    >
      <b2x.DrawerItem
        className="fw-light text-uppercase small"
        height={50}
        justifyContent="start"
        start={{ center: 'Accedi/Registrati' }}
      />
    </b2x.DrawerTriggerItem>
  );
};

const DrawerLocaleItem = () => {
  const { locale, shippingCountry } = b2x.useAppContext();
  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  return (
    <b2x.DrawerTriggerItem
      newDrawer={
        <Drawer>
          <div className="p-4">
            <b2x.LocaleForm singleColumn />
            {content?.body.alertChangeCountry && (
              <div className="small pt-3 text-center">{b2x.formatHtml(content.body.alertChangeCountry)}</div>
            )}
          </div>
        </Drawer>
      }
    >
      <b2x.DrawerItem
        className=""
        end={{
          center: (
            <span className="fw-light text-dark extra-small text-decoration-underline"> {t('account.edit')}</span>
          ),
        }}
        height={40}
        justifyContent="between"
        start={{
          center: (
            <div className="hstack gap-2 small">
              <Icon name="translate" size={25} />
              <span style={{ textTransform: 'initial' }}>
                {shippingCountry?.name} | {locale?.languageDescription}
              </span>
            </div>
          ),
        }}
      />
    </b2x.DrawerTriggerItem>
  );
};

export const useMobileNavigationOffcanvas = (props?: MobileNavigationOffcanvasProps) =>
  b2x.useStaticModal(MobileNavigationOffcanvas, 'offcanvas', props);
