import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import { auto } from '@popperjs/core';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Button, ButtonVariant } from './Button';
import { CustomRadio } from './CustomRadio';
import { t } from './i18n/i18n';
import { IconName, IconSize } from './Icon';
import { PriceBlock } from './PriceBlock';
import { Slider } from './slider/Slider';
import { SliderButton } from './slider/SliderButton';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = (props: ProductTileProps) => {
  return <b2x.ProductTile className={'card bg-white'} {...props} enableExtraInfo />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = (props: ProductTileBasicInfoProps) => (
  <b2x.ProductTileBasicInfo {...props} customComponent={CustomProductTileBasicInfo} />
);

const CustomProductTileBasicInfo = ({
  handleLinkClick,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: b2x.ProductTileBasicInfoProps) => {
  const { discountPercentageDecimal } = b2x.usePrice(product, sku?.id);

  const selectedSku = React.useMemo(
    () => product.skus?.find((productSku) => productSku.id === sku?.id),
    [product.skus, sku]
  );

  const { showModal } = b2x.useModals();

  const alternativeImage =
    selectedSku?.alternativeImages?.length &&
    selectedSku.alternativeImages.length > 0 &&
    selectedSku.alternativeImages[0];

  return (
    <div className="product-tile-basic-info">
      <div className="product-tile-media-container position-relative text-uppercase">
        <b2x.router.Link className="d-block stretched-link" onClick={handleLinkClick} to={product.url}>
          <b2x.Image
            alt={product.name}
            aspectRatio={b2x.appConfig.productImageAspectRatio}
            className="card-img-top"
            fluid
            format={imageFormat}
            src={selectedSku?.image ? selectedSku.image.src : product.image?.src}
          />
          {typeof alternativeImage === 'object' && (
            <b2x.Image
              alt={product.name}
              aspectRatio={b2x.appConfig.productImageAspectRatio}
              className="alternative-image"
              fluid
              format={imageFormat}
              {...alternativeImage}
            />
          )}
        </b2x.router.Link>
        <div className="position-absolute top-0 py-2 px-2 px-lg-3 w-100">
          {(product.tags?.includes('MIA_NEW') || product.tags?.includes('MIA_LIMITED')) && (
            <b2x.Row>
              {product.tags.map((tag) => (
                <b2x.Col className="sticker-container mb-1 pe-0" key={tag} size={{ lg: auto, xs: 12 }}>
                  <span className="rounded-1 px-2 bg-mint">
                    {tag === 'MIA_NEW' ? t('misc.MIA_NEW') : t('misc.MIA_LIMITED')}
                  </span>
                </b2x.Col>
              ))}
            </b2x.Row>
          )}
          {b2x.appConfig.enableWishlist && (
            <div className="wishlist-action-container position-absolute top-0 end-0 p-2">
              <b2x.WishlistButtonHelper product={product} sku={sku}>
                {({ handleWishlistButtonClick, inWishlist }) => (
                  <Button
                    iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 30 }}
                    onClick={handleWishlistButtonClick}
                    type="button"
                    variant="blank"
                  />
                )}
              </b2x.WishlistButtonHelper>
            </div>
          )}
        </div>
        {(discountPercentageDecimal || (product.offers && product.offers.length > 0)) && (
          <div className="position-absolute bottom-0 w-100 py-2 px-2 px-lg-3">
            <b2x.Row>
              {discountPercentageDecimal && (
                <b2x.Col className="sticker-promo-container mb-1 pe-0" size={{ lg: auto, xs: 12 }}>
                  <span className="rounded-1 px-2 bg-promo text-white">
                    {t('product.promo')} {`-${discountPercentageDecimal}%`}
                  </span>
                </b2x.Col>
              )}
              {product.offers && product.offers.length > 0 && (
                <>
                  {product.offers.map((offer) => (
                    <b2x.Col className="sticker-promo-container mb-1 pe-0" key={offer.id} size={{ lg: auto, xs: 12 }}>
                      <span className="rounded-1 px-2 bg-promo text-white zindex-modal">
                        {offer.shortDescription}
                        <b2x.Button
                          className={'btn-link text-white'}
                          iconStart={{ name: 'info', size: 14 }}
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => {
                            showModal({
                              children: offer.description,
                              size: 'default',
                              title: offer.shortDescription ? offer.shortDescription : '',
                            });
                          }}
                          variant="blank"
                        />
                      </span>
                    </b2x.Col>
                  ))}
                </>
              )}
            </b2x.Row>
          </div>
        )}
        {/*         {product.skus && product.skus.length - 1 > 1 && (
          <div className="position-absolute bottom-0 w-100 p-2">
            <div className="sticker-shade-container">
              <span className="rounded-1 px-2 bg-gray-200">
                +{product.skus.length - 1} {t('product.size', { count: product.skus.length })}
              </span>
            </div>
          </div>
        )}
 */}
      </div>
      <div className="card-body position-relative p-2 p-lg-3 pb-lg-0">
        <b2x.EqualHeightElement name={`product-tile-info-${productsPerRow}`}>
          {product.descriptionShort && (
            <p className="card-text mb-2 extra-small">{b2x.formatHtml(product.descriptionShort)}</p>
          )}
          <h6 className="card-title fw-semi-bold mb-0 mb-lg-1">
            <b2x.router.Link
              className="stretched-link text-decoration-none "
              onClick={handleLinkClick}
              to={product.url}
            >
              {product.name}
            </b2x.router.Link>
          </h6>
          <b2x.SummaryReviewsPreview productId={product.id} />
          {product.skus && product.skus.length - 1 > 1 && (
            <div className="sticker-shade-container my-1">
              <span className="rounded-1 px-2 bg-gray-200 small">
                +{product.skus.length - 1} {t('product.size', { count: product.skus.length })}
              </span>
            </div>
          )}
        </b2x.EqualHeightElement>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({
  fieldsHelper,
  priceHelper,
  productsPerRow,
  selectedSku,
}: ProductTileExtraInfoProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
  return (
    <div className="product-tile-extra-info">
      <div className="card-body  p-2 p-lg-3 pt-0">
        <b2x.EqualHeightElement name={`product-tile-slider-${productsPerRow}`}>
          <b2x.Row>
            <b2x.Col size={12}>
              {fieldsHelper.skus.formFields.length > 1 && (
                <div className="skus-container mx-n3">
                  <b2x.FormGroup {...fieldsHelper.skus.formGroup} label={undefined}>
                    <div className="d-flex position-relative">
                      <div className="ps-2">
                        <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
                      </div>
                      <Slider
                        navigation
                        sliderNextEl={sliderNextButton}
                        sliderPrevEl={sliderPrevButton}
                        slidesPerView={'auto'}
                        spaceBetween={8}
                        watchSlidesProgress
                      >
                        {fieldsHelper.skus.formFields.map(({ radio, sku }) => (
                          <SwiperSlide key={sku.id}>
                            {(slideData) => (
                              <b2x.Radio {...radio} inline noMargin>
                                <CustomRadio sku={sku} />
                              </b2x.Radio>
                            )}
                          </SwiperSlide>
                        ))}
                      </Slider>
                      <div className="pe-2">
                        <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
                      </div>
                    </div>
                  </b2x.FormGroup>
                </div>
              )}
            </b2x.Col>
          </b2x.Row>
        </b2x.EqualHeightElement>
        <b2x.EqualHeightElement name={`product-tile-add-to-cart-${productsPerRow}`}>
          <b2x.Row gap={0}>
            <b2x.Col className="d-none d-lg-block" size={'auto'}>
              <b2x.FormGroup
                {...fieldsHelper.quantity.formGroup}
                className="text-center"
                label={undefined}
                noMarginBottom
              >
                <b2x.Select
                  {...fieldsHelper.quantity.select}
                  className="quantity-select text-center"
                  includeEmptyOption={false}
                  placeholder={undefined}
                />
              </b2x.FormGroup>
            </b2x.Col>
            <b2x.Col className="d-flex align-items-center justify-content-center" size={''}>
              <div className="product-tile-price-container mb-2 mb-lg-0">
                {priceHelper && (
                  <PriceBlock
                    classNames={{ crossedOutPrice: 'fs-6', price: 'fs-6', specialPrice: 'fs6' }}
                    gap={2}
                    hiddenDiscountLabel
                    hiddenDiscountPercentage
                    priceHelper={priceHelper}
                  />
                )}
              </div>
            </b2x.Col>
            <b2x.Col className="text-end d-grid d-lg-block" size={{ lg: 'auto', xs: 12 }}>
              {/* <b2x.Button
                className="submit-button"
                iconStart={{ name: 'cart', size: 24 }}
                justifyContent="center"
                {...fieldsHelper.buttons.submit}
                label={undefined}
              /> */}
              <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                fieldsHelper={fieldsHelper}
                selectedSku={selectedSku}
                submitButton={{
                  className: 'submit-button',
                  iconStart: { name: 'cart', size: 24 },
                  justifyContent: 'center',
                  label: undefined,
                }}
              />
            </b2x.Col>
            {b2x.appConfig.enableBestPrice && priceHelper?.bestPriceValue && (
              <b2x.Col size={12}>
                <div className="extra-small lh-1 mt-2">
                  <b2x.BestPrice priceHelper={priceHelper} />
                </div>
              </b2x.Col>
            )}
          </b2x.Row>
        </b2x.EqualHeightElement>
      </div>
    </div>
  );
};
