import { ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useShopsApi } from '../api/useShopsApi';
import { Button } from '../Button';
import { Col } from '../Col';
import { Container } from '../Container';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { Image } from '../Image';
import facebookShareImage from '../images/share/facebook.svg';
import instagramShareImage from '../images/share/instagram.svg';
import twitterShareImage from '../images/share/x.svg';
import { Row } from '../Row';
import { useSharingModal } from '../SharingModal';
import { EntertainmentBusiness } from '../structuredData/EntertainmentBusiness';
import { Swiper } from '../Swiper';
import { SwiperContext } from '../SwiperContext';
import { GoogleMapInstance, useGoogleMap } from '../useGoogleMap';
import { useShareButtonClickHandler } from '../useShareButtonClickHandler';
import { formatHtml, formatShopAddress, getCurrentBreakpoint, uiClassName, untilBreakpoint } from '../util';
import { useDesktopPhoneModal } from './DesktopPhoneModal';
import { ShopThumbnail } from './ShopThumbnail';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface DetailsPanelProps {
  closeDetailsPanel(): void;
  googleMap: GoogleMapInstance;
  shop?: ShopApiDto;
}

export const DetailsPanel = ({ closeDetailsPanel, shop: shopProp }: DetailsPanelProps) => {
  const { DetailsPanelServices, getShopName, iconArrowLeft, variantPrimary } = useStoreLocatorContext();

  const [shop, setShop] = React.useState<ShopApiDto | undefined>();
  const { getShop } = useShopsApi();

  // All'apertura, completo con una nuova richiesta di dettaglio le info aggiuntive dello shop, maggiorni rispetto a quello disponibili nel listing.
  React.useEffect(() => {
    setShop(shopProp);
    if (shopProp) {
      getShop(shopProp.id, {
        populate: {
          additionalProperties: true,
          alternativeImages: true,
          image: true,
        },
      }).then((response) => {
        setShop({ ...shopProp, ...response.data });
      });
    }
  }, [getShop, shopProp]);

  return (
    <Div
      className={classnames(uiClassName({ bs5: 'position-absolute h-100 bg-white' }), { in: shop !== undefined })}
      display="flex"
      flexDirection="column"
      id="details-panel"
    >
      {shop && (
        <>
          <Button
            className={classnames('back-button', uiClassName({ bs5: 'w-100 text-white' }))}
            onClick={closeDetailsPanel}
            style={{ borderRadius: 0, display: 'block' }}
            variant={variantPrimary}
          >
            <Div display="flex">
              <Div>
                <Icon name={iconArrowLeft} size={16} />
              </Div>
              <Div className={uiClassName({ bs5: 'flex-grow-1' })} style={{ minWidth: 0, padding: '0 24px 0 8px' }}>
                <Div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {getShopName(shop)}
                </Div>
              </Div>
            </Div>
          </Button>
          <Div className={uiClassName({ bs5: 'overflow-auto' })}>
            <Container>
              <Div paddingBottom={4} paddingTop={3}>
                <Div marginBottom={3}>
                  <SmallMap shop={shop} />
                </Div>
                {/* <Gallery className="mb-4" shop={shop} /> */}
                <Div marginBottom={3}>
                  <ShopInfo shop={shop} />
                </Div>
                <Div marginBottom={3}>
                  <Address shop={shop} />
                </Div>
                <Div marginBottom={3}>
                  <OpeningTime shop={shop} />
                </Div>
                <Div marginBottom={3}>
                  <OtherServicesSlider shop={shop} />
                </Div>
                <Action shop={shop} />
              </Div>
            </Container>
            <Container id="details-panel-footer">
              <Div paddingBottom={5} paddingTop={4}>
                {DetailsPanelServices && (
                  <Div marginBottom={4}>
                    <DetailsPanelServices shop={shop} />
                  </Div>
                )}
                <Div marginBottom={4}>
                  <Description shop={shop} />
                </Div>
                <Social shop={shop} />
              </Div>
            </Container>
          </Div>
          <EntertainmentBusiness shop={shop} />
          <Helmet>
            {shop.metaData?.title && <title>{shop.metaData.title}</title>}
            {shop.metaData?.description && <meta content={shop.metaData.description} name="description" />}
          </Helmet>
        </>
      )}
    </Div>
  );
};

interface CommonProps {
  className?: string;
  shop: ShopApiDto;
}

interface SmallMapProps extends CommonProps {}

const SmallMap = ({ className, shop }: SmallMapProps) => {
  const { getDetailsPanelMarkerContent, getShopName } = useStoreLocatorContext();

  const [ref] = useGoogleMap({
    center:
      shop.address?.latitude && shop.address.longitude
        ? { lat: shop.address.latitude, lng: shop.address.longitude }
        : undefined,
    clickableIcons: false,
    countryCode: 'IT',
    disableDefaultUI: true,
    markers: [
      {
        content: getDetailsPanelMarkerContent ? getDetailsPanelMarkerContent(shop) : undefined,
        id: shop.id,
        position:
          shop.address?.latitude && shop.address.longitude
            ? { lat: shop.address.latitude, lng: shop.address.longitude }
            : undefined,
        title: getShopName(shop),
      },
    ],
    zoom: 15,
  });

  return (
    <Div className={classnames('small-map', className)}>
      <Div
        innerRef={ref}
        style={{
          height: 100,
        }}
      />
    </Div>
  );
};

// interface GalleryProps extends CommonProps {}

// const Gallery = ({ className, shop }: GalleryProps) => {
//   const { showModal } = useModals();

//   const imageList = React.useMemo(
//     () => [...(shop.image ? [shop.image] : []), ...(shop.alternativeImages ? shop.alternativeImages : [])],
//     [shop.alternativeImages, shop.image]
//   );

//   const handleButtonClick = React.useCallback(() => {
//     showModal({
//       centered: true,
//       children: <GallerySlider slides={imageList} />,
//       closable: true,
//       scrollable: true,
//       size: 'large',
//       title: t('modal.gallery.title'),
//     });
//   }, [imageList, showModal]);

//   return (
//     <>
//       {imageList.length > 0 && (
//         <Div className={classnames('gallery', className)}>
//           <Row gap={3}>
//             {imageList.slice(0, 3).map((image, index) => (
//               // eslint-disable-next-line react/no-array-index-key
//               <Col key={image.id} size={imageList.length === 1 ? 12 : imageList.length === 2 ? 6 : 4}>
//                 <BlankButton className="overflow-hidden rounded-2" onClick={handleButtonClick}>
//                   <FixedAspectRatioBox
//                     alignItems="center"
//                     aspectRatio={1 / 1}
//                     className="bg-primary"
//                     justifyContent="center"
//                   >
//                     {/* eslint-disable-next-line react/forbid-elements */}
//                     <img
//                       alt={image.alt}
//                       // className={classnames({ 'blur-effect': index === 2 && imageList.length > 3 })}
//                       src={image.src}
//                       style={{ height: '100%', objectFit: 'cover', width: '100%' }}
//                     />
//                     {/* <Image
//                     className={classnames({ 'blur-effect': index === 2 && imageList.length > 3 })}
//                     responsive
//                     src={image.src}
//                     style={{ objectFit: 'cover' }}
//                   /> */}
//                   </FixedAspectRatioBox>
//                 </BlankButton>
//                 {/* <BlankButton onClick={handleButtonClick}>asd</BlankButton> */}
//                 {/* <Button className="overflow-hidden radius-1" onClick={handleButtonClick} variant="blank">
//                   <Div className="position-relative">
//                     <Image
//                       className={classnames({ 'blur-effect': index === 2 && imageList.length > 3 })}
//                       responsive
//                       src={image.src}
//                     />
//                     {index === 2 && imageList.length > 3 && (
//                       <Div className="position-absolute w-100 h-100 start-0 top-0 d-flex align-items-center justify-content-center">
//                         <Div className="text-white" style={{ fontSize: '30px' }}>
//                           <span className="fw-light">+</span>
//                           <span className="fw-bold">{imageList.length - 2}</span>
//                         </Div>
//                       </Div>
//                     )}
//                   </Div>
//                 </Button> */}
//               </Col>
//             ))}
//           </Row>
//         </Div>
//       )}
//     </>
//   );
// };

// interface GallerySliderProps {
//   slides: Array<ImageApiDto>;
// }

// const GallerySlider = ({ slides }: GallerySliderProps) => {
//   return (
//     <Div className="gallery-slider">
//       <Div className="position-relative">
//         <Swiper
//           modules={[Navigation, Pagination]}
//           navigation={{ nextEl: '.gallery-slider .swiper-next', prevEl: '.gallery-slider .swiper-prev' }}
//           pagination={{ clickable: true, el: '.gallery-slider .swiper-pagination', enabled: true }}
//           slidesPerView={1}
//           spaceBetween={0}
//           watchSlidesProgress
//         >
//           {slides.map((slide) => (
//             <SwiperSlide className="text-center" key={`A-${slide}`}>
//               {(slideData) => <Image responsive src={slide.src} />}
//             </SwiperSlide>
//           ))}
//         </Swiper>
//         <Div className="d-none d-lg-block position-absolute top-0 start-0 w-100 h-100">
//           <Div className="d-flex align-items-center justify-content-between h-100">
//             <Button className="swiper-prev" iconStart={{ name: 'arrow-left', size: 24 }} />
//             <Button className="swiper-next" iconStart={{ name: 'arrow-right', size: 24 }} />
//           </Div>
//         </Div>
//       </Div>
//       <Div className="swiper-pagination"></Div>
//     </Div>
//   );
// };

interface ShopInfoProps extends CommonProps {}

const ShopInfo = ({ className, shop }: ShopInfoProps) => {
  const { getShopDescription, getShopName, iconShare, variantPrimary } = useStoreLocatorContext();

  //   const [SharingModal, showSharingModal] = useSharingModal<ButtonVariant>({ buttonVariant: 'outline-gray-700' });
  const [SharingModal, showSharingModal] = useSharingModal({ buttonVariant: 'outline-gray-700' });

  const handleShareButtonClick = useShareButtonClickHandler({
    data: {
      title: shop.metaData?.title,
      url: window.location.href,
    },
    fallback: showSharingModal,
  });

  return (
    <Div className={classnames('shop-info', className)}>
      <Row gap={2}>
        <Col size={'auto'}>
          <ShopThumbnail shop={shop} />
        </Col>
        <Col>
          <Div marginBottom={1}>
            <h5 className={uiClassName({ bs5: `text-${variantPrimary} fw-bold` })}>{getShopName(shop)}</h5>
          </Div>
          {getShopDescription && (
            <p className={uiClassName({ bs5: 'lh-sm m-0' })}>
              <small>{getShopDescription(shop)}</small>
            </p>
          )}
        </Col>
        <Col size={'auto'}>
          <Button
            className={uiClassName({ bs5: 'p-2' })}
            iconStart={{ name: iconShare, size: 24 }}
            onClick={handleShareButtonClick}
            style={{ background: 'transparent', borderColor: 'transparent' }}
            variant="blank"
          />
        </Col>
      </Row>
      {SharingModal}
    </Div>
  );
};

interface AddressProps extends CommonProps {}

const Address = ({ className, shop }: AddressProps) => {
  const { iconPin } = useStoreLocatorContext();

  return (
    <React.Fragment>
      {shop.address && (
        <Div className={classnames('address', className)}>
          <table>
            <tbody>
              <tr>
                <td>
                  <Icon className={uiClassName({ bs5: 'me-2' })} name={iconPin} size={16} />
                </td>
                <td className={uiClassName({ bs5: 'lh-sm' })}>{formatShopAddress(shop.address)}</td>
              </tr>
            </tbody>
          </table>
        </Div>
      )}
    </React.Fragment>
  );
};

interface OpeningTimeProps extends CommonProps {}

const OpeningTime = ({ className, shop }: OpeningTimeProps) => {
  return (
    <Div className={classnames('opening-time ', uiClassName({ bs5: 'small lh-sm' }), className)}>
      <table>
        <tbody>
          {shop.timeRangeByDays?.map((day) => {
            const state: 'open' | 'closed' | undefined =
              shop.currentTimeRange?.dayOfWeek !== day.dayOfWeek
                ? undefined
                : shop.currentTimeRange?.state === 'OPEN'
                ? 'open'
                : 'closed';
            return (
              <tr
                className={classnames({ 'text-green': state === 'open' }, { 'text-danger': state === 'closed' })}
                key={day.dayOfWeek}
              >
                <td>
                  {shop.currentTimeRange?.dayOfWeek === day.dayOfWeek && (
                    <Icon className={uiClassName({ bs5: 'me-2 align-top' })} name="clock-fill" size={16} />
                  )}
                </td>
                <td>
                  {day.dayOfWeek && `${t(`storeLocator.shopDetails.openingDays.${day.dayOfWeek}`).substring(0, 3)}`}
                  {day.timeSlots?.map((timeSlot, index) => (
                    <React.Fragment key={timeSlot.timeTo}>
                      <span
                        className={classnames(
                          { 'text-gray-500': state === undefined },
                          { 'text-green': state === 'open' },
                          { 'text-danger': state === 'closed' }
                        )}
                      >{`${index === 0 ? ' •' : ' /'} ${timeSlot.timeFrom} - ${timeSlot.timeTo}`}</span>
                    </React.Fragment>
                  ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Div>
  );
};

interface OtherServicesSliderProps extends CommonProps {}

const OtherServicesSlider = ({ className, shop }: OtherServicesSliderProps) => {
  const { getShopOtherServices, iconArrowLeft, iconArrowRight } = useStoreLocatorContext();

  const otherServices = getShopOtherServices ? getShopOtherServices(shop) : undefined;

  return (
    <React.Fragment>
      {otherServices && otherServices.length > 0 && (
        <Div className={classnames('other-services-slider', className)} paddingY={2}>
          <Div className={uiClassName({ bs5: 'position-relative' })} paddingX={2}>
            <SwiperContext>
              {({ navigationNextElRef, navigationPrevElRef }) => (
                <>
                  <Swiper
                    navigation={{ custom: true }}
                    slides={otherServices.map((service) => (
                      <Div
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        key={`other-service-${service.name}`}
                        paddingX={2}
                        textAlign="center"
                      >
                        <Icon name={service.icon} size={24} />
                        <h6 className={uiClassName({ bs5: 'extra-small' })}>{service.name}</h6>
                      </Div>
                    ))}
                    slidesPerView={4}
                    spaceBetween={0}
                    watchSlidesProgress
                  />
                  <Button
                    className="swiper-prev"
                    iconStart={{ name: iconArrowLeft, size: 14 }}
                    innerRef={navigationPrevElRef}
                  />
                  <Button
                    className="swiper-next"
                    iconStart={{ name: iconArrowRight, size: 14 }}
                    innerRef={navigationNextElRef}
                  />
                </>
              )}
            </SwiperContext>
          </Div>
        </Div>
      )}
    </React.Fragment>
  );
};

interface ActionProps extends CommonProps {}

const Action = ({ className, shop }: ActionProps) => {
  const { hidePhoneButton, iconDirections, iconPhoneCall } = useStoreLocatorContext();

  const [DesktopPhoneModal, showDesktopPhoneModal] = useDesktopPhoneModal({
    centered: untilBreakpoint('lg', getCurrentBreakpoint()) ? true : false,
    shop: shop,
    size: 'small',
  });

  return (
    <Div className={classnames('action', className)}>
      <Row gap={2}>
        {!hidePhoneButton && (
          <Col className={uiClassName({ bs5: 'd-grid' })} size={6}>
            {/* eslint-disable-next-line react/forbid-elements */}
            <a
              className={classnames(uiClassName({ bs5: 'd-block d-lg-none btn btn-secondary py-2 px-0' }), {
                disabled: !shop.phoneNumber,
              })}
              href={`tel:${shop.phoneNumber}`}
              rel="noreferrer"
            >
              <Div alignItems="center" display="flex" flexDirection="column">
                <Div marginBottom={1}>
                  <Icon name={iconPhoneCall} size={24} />
                </Div>
                <span className={uiClassName({ bs5: 'small lh-1' })}>{t('storeLocator.shopDetails.cta.call')}</span>
              </Div>
            </a>
            <Button
              className={uiClassName({ bs5: 'd-none d-lg-block py-2 px-0' })}
              disabled={!shop.phoneNumber}
              onClick={showDesktopPhoneModal}
              variant="secondary"
            >
              <Div alignItems="center" display="flex" flexDirection="column">
                <Div marginBottom={1}>
                  <Icon name={iconPhoneCall} size={24} />
                </Div>
                <span className={uiClassName({ bs5: 'small lh-1' })}>{t('storeLocator.shopDetails.cta.call')}</span>
              </Div>
            </Button>
          </Col>
        )}
        <Col className={uiClassName({ bs5: 'd-grid' })} size={hidePhoneButton ? 12 : 6}>
          {/* eslint-disable-next-line react/forbid-elements */}
          <a
            className={uiClassName({ bs5: 'btn btn-primary py-2 px-0' })}
            href={`https://www.google.com/maps/dir/?api=1&destination=${shop.address?.latitude},${shop.address?.longitude}`}
            rel="noreferrer"
            target="_blank"
          >
            <Div alignItems="center" display="flex" flexDirection="column">
              <Div marginBottom={1}>
                <Icon name={iconDirections} size={24} />
              </Div>
              <span className={uiClassName({ bs5: 'small lh-1' })}>{t('storeLocator.shopDetails.cta.directions')}</span>
            </Div>
          </a>
        </Col>
      </Row>
      {DesktopPhoneModal}
    </Div>
  );
};

export interface ServicesProps extends CommonProps {}

// const Services = ({ className, shop }: ServicesProps) => {
//   // const bettingServices = getBettingServices(shop);
//   // const onlineGamesServices = getOnlineGamesServices(shop);
//   // const otherServices = getOtherServices(shop);

//   return (
//     <React.Fragment>
//       {/* {((bettingServices && bettingServices.length > 0) ||
//         (onlineGamesServices && onlineGamesServices.length > 0) ||
//         (otherServices && otherServices.length > 0)) && (
//         <Div className={classnames('services', className)}>
//           <h5 className="fw-bold mb-3">{t('shopDetails.services.title')}</h5>
//           <Accordion id="services" variant="bordless">
//             {bettingServices && bettingServices.length > 0 && (
//               <AccordionItem id="betting" show title={t('shopDetails.services.types.betting')}>
//                 <ul className="list-unstyled m-0">
//                   {bettingServices.map((service) => (
//                     <li key={service.name}>{t(`shopDetails.services.${service.name}` as ResourceKeys)}</li>
//                   ))}
//                 </ul>
//               </AccordionItem>
//             )}
//             {onlineGamesServices && onlineGamesServices.length > 0 && (
//               <AccordionItem id="online-games" title={t('shopDetails.services.types.onlineGames')}>
//                 <ul className="list-unstyled m-0">
//                   {onlineGamesServices.map((service) => (
//                     <li key={service.name}>{t(`shopDetails.services.${service.name}` as ResourceKeys)}</li>
//                   ))}
//                 </ul>
//               </AccordionItem>
//             )}
//             {otherServices && otherServices.length > 0 && (
//               <AccordionItem id="other" title={t('shopDetails.services.types.otherServices')}>
//                 <ul className="list-unstyled m-0">
//                   {otherServices.map((service) => (
//                     <li key={service.name}>{t(`shopDetails.services.${service.name}` as ResourceKeys)}</li>
//                   ))}
//                 </ul>
//               </AccordionItem>
//             )}
//           </Accordion>
//         </Div>
//       )} */}
//     </React.Fragment>
//   );
// };

interface DescriptionProps extends CommonProps {}

const Description = ({ className, shop }: DescriptionProps) => {
  return (
    <>
      {shop.additionalProperties && shop.additionalProperties['SHOP_DESCRIPTION'] && (
        <Div className={classnames('description', className)}>
          <Div marginBottom={3}>
            <h5 className={uiClassName({ bs5: 'fw-bold' })}>{t('storeLocator.shopDetails.description')}</h5>
          </Div>
          <Div>{formatHtml(shop.additionalProperties['SHOP_DESCRIPTION'].value)}</Div>
        </Div>
      )}
    </>
  );
};

interface SocialProps extends CommonProps {}

const Social = ({ className, shop }: SocialProps) => {
  let instagram: string | undefined;
  let facebook: string | undefined;
  let twitter: string | undefined;
  if (shop.additionalProperties) {
    instagram = shop.additionalProperties['SHOP_SOCIALS_INSTAGRAM']?.value;
    facebook = shop.additionalProperties['SHOP_SOCIALS_FACEBOOK']?.value;
    twitter = shop.additionalProperties['SHOP_SOCIALS_TWITTER']?.value;
  }
  return (
    <>
      {(instagram || facebook || twitter) && (
        <Div className={classnames('social', className)}>
          <Div marginBottom={3}>
            <h5 className={uiClassName({ bs5: 'fw-bold' })}>{t('storeLocator.shopDetails.socialNetwork')}</h5>
          </Div>
          <Row gap={2}>
            {instagram && (
              <Col size={'auto'}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <a href={instagram}>
                  <Image height={30} src={instagramShareImage} />
                </a>
              </Col>
            )}
            {facebook && (
              <Col size={'auto'}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <a href={facebook}>
                  <Image height={30} src={facebookShareImage} />
                </a>
              </Col>
            )}
            {twitter && (
              <Col size={'auto'}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <a href={twitter}>
                  <Image height={30} src={twitterShareImage} />
                </a>
              </Col>
            )}
          </Row>
        </Div>
      )}
    </>
  );
};
