import { b2x } from '@b2x/react/src';
import React from 'react';

import { Box } from '../Box';
import { RegistrationFormContentType } from '../contentTypes';
import { Page } from './Page';
export interface RegistrationPageProps {}

export const RegistrationPage = (props: RegistrationPageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');
  const { callbackUrl } = b2x.useAppContext();
  const { setCallbackUrl } = b2x.useAppStaticContext();
  const navigate = b2x.router.useNavigate();

  const redirectTo = React.useCallback(() => {
    callbackUrl && navigate(callbackUrl);
    setCallbackUrl(undefined);
  }, [callbackUrl, navigate, setCallbackUrl]);

  return (
    <Page pinkBackground thingsToLoadBeforePageReady={[content]}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 col-xl-5">
            <Box>
              <div className="mb-4 ">
                <h1 className="h2 text-primary text-center mb-3">{b2x.formatHtml(content?.body.title)}</h1>
                <p className="text-center">{b2x.formatHtml(content?.body.subTitle)}</p>
              </div>
              <b2x.CustomerForm className="mb-4" onSuccess={callbackUrl ? redirectTo : undefined} />
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
