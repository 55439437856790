import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ArticlePageContentType } from './contentTypes';
import { t } from './i18n/i18n';

export interface ArticleTileProps extends ArticlePageContentType {
  classname?: string;
  cta?: string;
  onlyOneFormatForImagePreview?: boolean;
  pages?: Array<b2x.PageApiDto>;
}
export const ArticleTile = ({
  classname,
  cta,
  imagePreview,
  onlyOneFormatForImagePreview,
  pages,
  title,
}: ArticleTileProps) => {
  return (
    <div className={classnames('article-tile rounded border', classname)}>
      <b2x.ConditionalWrapper
        condition={cta !== undefined}
        wrapper={<b2x.router.Link style={{ display: 'block' }} to={cta} />}
      >
        <b2x.EqualHeightElement name="article-tile-image">
          {onlyOneFormatForImagePreview && imagePreview?.src?.xs ? (
            <b2x.Image className="rounded-top" fluid src={imagePreview.src.xs.url} />
          ) : (
            <b2x.ImageFromContent className="rounded-top" fluid {...imagePreview} />
          )}
        </b2x.EqualHeightElement>
      </b2x.ConditionalWrapper>

      <div className="content-wrapper p-3">
        {title && (
          <b2x.EqualHeightElement name="article-tile-title">
            <h5 className="fw-semi-bold title mb-2">{b2x.formatHtml(title)}</h5>
          </b2x.EqualHeightElement>
        )}

        <div className="content-bottom-wrapper d-flex justify-content-between align-items-start">
          {pages && (
            <b2x.EqualHeightElement name="article-tile-tags">
              <div className="tags d-flex flex-wrap me-3">
                {pages.map((page) => (
                  <b2x.router.Link className="extra-small btn-link me-2" key={page.id} to={page.fullPath}>
                    #{b2x.formatHtml(page.name)}
                  </b2x.router.Link>
                ))}
              </div>
            </b2x.EqualHeightElement>
          )}
          {cta && (
            <b2x.router.Link className="btn btn-outline-primary align-self-start" to={cta}>
              {t('misc.readMore')}
            </b2x.router.Link>
          )}
        </div>
      </div>
    </div>
  );
};
