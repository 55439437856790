import './NewsletterSubscribePage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { NewsletterContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Page } from './Page';

export interface NewsletterSubscribePageProps {}

export const NewsletterSubscribePage = () => {
  const content = b2x.useContent<NewsletterContentType>('NEWSLETTER_PAGE_CONTENT');

  return (
    <Page className="newsletter-subscribe-page" noPaddingBottom noPaddingTop>
      <div className="position-relative">
        {content?.body.img && (
          <div className="position-absolute w-100 h-100" style={{ zIndex: 10 }}>
            <b2x.ImageAsBackgroundFromContent className="w-100" {...content.body.img} />
          </div>
        )}
        <div className="page-content position-relative" style={{ zIndex: 20 }}>
          <Container>
            <b2x.Row className="justify-content-center">
              <b2x.Col size={{ md: 10, xl: 7, xs: 12 }}>
                <div className="py-5">
                  <div className="copy-container text-white text-center mb-3 mb-md-5">
                    {content?.body.title && (
                      <h1 className="h1 fw-semi-bold mb-3 mb-md-5">{b2x.formatHtml(content.body.title)}</h1>
                    )}
                    {content?.body.subTitle && (
                      <h3 className="fw-semi-bold mb-3">{b2x.formatHtml(content.body.subTitle)}</h3>
                    )}
                    {content?.body.content && <div className="mb-3">{b2x.formatHtml(content.body.content)}</div>}
                  </div>
                  <div className="form-container">
                    <b2x.Row className="justify-content-center">
                      <b2x.Col size={{ md: 9, xs: 12 }}>
                        <NewsletterForm
                          className="bg-white rounded p-4 p-md-5"
                          ctaContainerClassName="text-center mt-4"
                          emailFieldStyle="textInput"
                          source="website-modal"
                        />
                      </b2x.Col>
                    </b2x.Row>
                  </div>
                </div>
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      </div>
    </Page>
  );
};
