import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { Container } from './Container';
import { FooterIconsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';
import { Slider } from './slider/Slider';

export interface FooterIconsProps {
  className?: string;
}

export const FooterIcons = ({ className }: FooterIconsProps) => {
  const footerIconsContent = b2x.useContent<FooterIconsContentType>('FOOTER_ICONS_CONTENT');
  return (
    <>
      {footerIconsContent?.body.iconsList && (
        <Container>
          <b2x.Row>
            <b2x.Col className="pe-0 pe-lg-3" size={12}>
              <b2x.EqualHeight>
                <Slider
                  responsive={{
                    lg: {
                      slidesPerView: 5,
                    },
                    md: {
                      slidesPerView: 4.35,
                    },
                  }}
                  slidesPerView={2.35}
                  spaceBetween={20}
                >
                  {footerIconsContent.body.iconsList.map((item, index) => (
                    <SwiperSlide key={item.contentSectionId}>
                      <div className="icon-slide text-center">
                        <b2x.router.Link to={item.url}>
                          {item.img ? (
                            <b2x.EqualHeightElement name="sliderIconImage">
                              <b2x.ImageFromContent {...item.img} className="mb-3" fluid />
                            </b2x.EqualHeightElement>
                          ) : (
                            item.name &&
                            isIconName(item.name) && (
                              <Icon className="mb-4" fontSize={72} name={item.name as IconName} />
                            )
                          )}
                        </b2x.router.Link>
                        {item.iconTitle && <h6 className="fw-bold mb-2">{item.iconTitle}</h6>}
                        {item.title && <div className="extra-small">{item.title}</div>}
                      </div>
                    </SwiperSlide>
                  ))}
                </Slider>
              </b2x.EqualHeight>
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}
    </>
  );
};
