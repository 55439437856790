import { Resource } from '../i18n';

export const it: Resource = {
  customerCare: {
    contact: 'Contattaci al',
  },
  form: {
    newsletterForm: {
      success: {
        body: 'Ti confermiamo che la tua iscrizione alla newsletter è andata a buon fine. Ti aggiorneremo su novità, tendenze e offerte su misura per te.',
        title: 'Benvenuto nel mondo Mia Cosmetics!',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telefono' },
    },
  },
  misc: {
    MIA_LIMITED: 'Limited edition',
    MIA_NEW: 'New in',
    article: { share: "Condividi l'articolo" },
    newProductSticker: 'New in',
    share: 'Condividi',
    storeName: 'Mia Cosmetics',
  },
  product: {
    code: 'Cod',
    promo: 'Promo',
    relatedProduct: {
      title: 'Provalo con',
    },
    selectSize: 'Seleziona un colore',
    size: '',
    size_one: 'Colore',
    size_other: 'Colori',
  },
  storeLocator: {
    badges: {
      corner: 'Qui trovi Mia',
      shop: 'Negozio Mia',
    },
  },
  welcome: 'Benvenuto su store-miacosmetics!',
};
