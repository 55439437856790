import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext, useAppStaticContext } from '../AppContext';

export interface PageProps extends b2x.PageProps {
  backgroundImage?: b2x.contentTypes.ImageContentSection;
  children?: React.ReactNode;
  headerCheckout?: boolean;
  hiddenFooter?: boolean;
  minimalFooter?: boolean;
  noPaddingBottom?: boolean;
  noPaddingTop?: boolean;
  pinkBackground?: boolean;
  transparentHeader?: boolean;
}

export const Page = ({
  backgroundImage,
  children,
  className,
  headerCheckout = false,
  hiddenFooter = false,
  minimalFooter = false,
  noPaddingBottom = false,
  noPaddingTop = false,
  pinkBackground = false,
  transparentHeader = false,
  ...otherProps
}: PageProps) => {
  const { pageReady } = b2x.useAppContext();
  const { headerHeight } = useAppContext();

  const { setHeaderCheckout, setHiddenFooter, setMinimalFooter, setPinkBackgroundPage, setTransparentHeader } =
    useAppStaticContext();

  React.useEffect(() => {
    pageReady && setTransparentHeader(transparentHeader);
    pageReady && setMinimalFooter(minimalFooter);
    pageReady && setHiddenFooter(hiddenFooter);
    pageReady && setHeaderCheckout(headerCheckout);
    pageReady && setPinkBackgroundPage(pinkBackground);
  }, [
    pinkBackground,
    minimalFooter,
    headerCheckout,
    setHeaderCheckout,
    pageReady,
    setPinkBackgroundPage,
    setMinimalFooter,
    transparentHeader,
    setTransparentHeader,
    setHiddenFooter,
    hiddenFooter,
  ]);

  React.useEffect(() => {
    if (pinkBackground) {
      document.body.classList.add('bg-chilean-pink');
    } else {
      document.body.classList.remove('bg-chilean-pink');
    }
  }, [pinkBackground]);

  return (
    <b2x.Page {...otherProps} className={classnames(className, 'h-100')}>
      <div className="Page h-100 position-relative">
        {backgroundImage && (
          <div className="position-absolute h-100 w-100">
            <b2x.ImageAsBackgroundFromContent {...backgroundImage} />
          </div>
        )}
        <div
          className={classnames(
            'position-relative h-100',
            'py-3 py-xl-5',
            { 'pt-0 pt-xl-0': noPaddingTop },
            { 'pb-0 pb-xl-0': noPaddingBottom }
          )}
          style={{
            marginBottom: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
            top: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
          }}
        >
          {children}
        </div>
      </div>
    </b2x.Page>
  );
};
