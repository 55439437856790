import './LandingGenericPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';
import { LandingGenericPageContentType } from '../contentTypes';
import { PageHeader } from '../PageHeader';
import { Section } from '../Section';
import { SliderB } from '../slider/SliderB';
import { Page } from './Page';

export interface LandingGenericPageProps {}

export const LandingGenericPage = (props: LandingGenericPageProps) => {
  const page = b2x.usePage<LandingGenericPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  return (
    <Page className="landing-page-generic" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {page?.content?.body.header && <PageHeader {...page.content.body.header} className={'mb-lg-5'} />}
      {page?.content?.body.sectionList?.map((section) => (
        <Section className={classnames(`bg-${section.background}`, 'py-3 py-lg-5')} key={section.contentSectionId}>
          <Container>
            {section.rowList?.map((row) => (
              <div
                className={classnames(
                  !row.disablePaddingBottom && !row.disablePaddingTop
                    ? 'py-3 py-lg-5'
                    : row.disablePaddingTop
                    ? 'pb-5'
                    : row.disablePaddingBottom && 'pt-5'
                )}
                key={row.contentSectionId}
              >
                <b2x.Row className="justify-content-center">
                  {(row.copy?.title || row.copy?.content || row.copy?.cta?.label) && (
                    <b2x.Col
                      className={classnames(
                        'order-2',
                        row.copy.alignment === 'left'
                          ? 'text-start'
                          : row.copy.alignment === 'right'
                          ? 'text-lg-end'
                          : row.copy.alignment === 'center' && 'text-center',
                        'd-flex align-items-center'
                      )}
                      size={{ lg: row.copy.columnSize as b2x.ColSize, xs: 12 }}
                    >
                      <div
                        className={classnames(
                          'w-100',
                          { 'pe-0 pe-lg-5': row.copy.alignment === 'left' && !row.beforeMediaContent },
                          { 'ps-0 ps-lg-5': row.beforeMediaContent }
                        )}
                      >
                        {row.copy.title && <h3 className="h2 fw-semi-bold">{b2x.formatHtml(row.copy.title)}</h3>}
                        {row.copy.content && <div>{b2x.formatHtml(row.copy.content)}</div>}
                        {row.copy.cta?.label && (
                          <div className="mt-3 mb-2">
                            <b2x.DeprecatedCta
                              className={classnames(
                                'btn',
                                row.copy.cta.variant ? `btn-${row.copy.cta.variant}` : 'btn-primary'
                              )}
                              cta={row.copy.cta}
                            >
                              {row.copy.cta.label}
                            </b2x.DeprecatedCta>
                          </div>
                        )}
                      </div>
                    </b2x.Col>
                  )}
                  {(row.media?.video?.src?.xs?.video || row.media?.img?.src?.xs) && (
                    <b2x.Col
                      className={classnames(
                        'd-flex align-items-center order-1',
                        row.beforeMediaContent ? 'order-lg-1' : 'order-lg-3'
                      )}
                      size={{ lg: row.media.columnSize as b2x.ColSize, xs: 12 }}
                    >
                      <div className="mb-3 mb-lg-0">
                        {row.media.video?.src?.xs?.video ? (
                          <b2x.DeprecatedVideoFromContent controls {...row.media.video} />
                        ) : (
                          row.media.img && <b2x.ImageFromContent {...row.media.img} className="rounded" fluid />
                        )}
                      </div>
                    </b2x.Col>
                  )}
                </b2x.Row>
              </div>
            ))}
          </Container>
        </Section>
      ))}
      {page?.products && page.products.length > 0 && (
        <Container className="pb-4">
          {page.content?.body.productsSliderTitle && (
            <h3 className="h2 fw-semi-bold text-center mb-5">{page.content.body.productsSliderTitle}</h3>
          )}
          <b2x.Listing name={`Landing page - ${page.content?.body.productsSliderTitle}`} products={page.products}>
            <SliderB products={page.products} />
          </b2x.Listing>
        </Container>
      )}
      {page?.content?.body.showContactForm && (
        <b2x.Div className="bg-chilean-pink">
          <Container>
            <b2x.Row className="justify-content-center">
              <b2x.Col size={{ lg: 8, xs: 12 }}>
                {page.content.body.titleContactForm && (
                  <h3 className="text-center fw-semi-bold my-5">
                    {b2x.formatHtml(page.content.body.titleContactForm)}
                  </h3>
                )}
                <b2x.Div className="box-content shadow-sm bg-white px-3 px-xl-5 py-3 py-xl-5 mb-5">
                  <b2x.CustomerCareForm />
                </b2x.Div>
              </b2x.Col>
            </b2x.Row>
          </Container>
        </b2x.Div>
      )}
    </Page>
  );
};
